import {
  ArrowCircleRightOutlined,
  Groups,
  HandymanOutlined,
  MoveToInbox,
} from "@mui/icons-material";
import * as React from "react";
import { Fade } from "react-reveal";
import { Link, useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { Button, useDisclosure, useToast } from "@chakra-ui/react";

import ModalDispaly from "./ModalDispaly";
import swal from "sweetalert";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { useState } from "react";

const Category = () => {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    // if (!message || !name || !email) {
    //   toast({
    //     title: "Unknown Error!",
    //     description: "Input the empty fields",
    //     status: "error",
    //     duration: "2000",
    //     isClosable: true,
    //     position: "top",
    //     colorScheme: "red",
    //     variant: "subtle",
    //   });
    // }
    if (message || name || email) {
      setLoading(true);
      emailjs
        .sendForm(
          "service_2dgc3fa",

          "template_dw6n13m",

          form.current,
          "g9C40xwxDFxlCPG9P"
        )
        //1 SERVICE ID
        //2 TEMPLATE_ID
        //3 PUBLIC KEY
        .then(
          (result) => {
            console.log(result.text);
            setLoading(false);
            toast({
              title: "Text sent",
              description: "we will get back to you shortly",
              status: "success",
              duration: "1500",
              isClosable: true,
              position: "top",
              colorScheme: "blue",
              variant: "subtle",
            });
            // setMessage("")
          },
          (error) => {
            console.log(error.text);
            setLoading(false);
            swal("", "An error occured", "info");
          }
        );
    }
    console.log(sendEmail);
  };
  const nav = useNavigate();
  const [count, setCount] = React.useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  React.useEffect(() => {
    console.log(count);
  }, []);

  return (
    <div className=" mt-3 steps">
      <br />
      <br />

      <div className="mx-3 mt-4">
        <Fade>
          <div className=" stats">
            <center>
              <h4 className="glitch" onClick={() => nav("/login")}>
                JOIN NOW <ArrowCircleRightOutlined />
              </h4>
              {/* <div class="progress" style={{ width: "15%" }}>
                <div class="color"></div>
              </div> */}

              <div className="line"></div>
              <div className="categories stats" style={{ width: "80%" }}>
                <div className="stat">
                  <main>
                    <Groups fontSize="inherit" />
                  </main>
                  {/* <ScrollTrigger onEnter={() => {setCount(true); alert('in')}} onExit={() => {setCount(false); alert('out')}} > */}
                  {count && (
                    <h4 className="fw-bold">
                      <CountUp start={100} end={5005} duration={15} delay={0} />
                    </h4>
                  )}
                  {/* </ScrollTrigger> */}

                  <small className="fw-bold">Total no of investors</small>
                </div>
              </div>
              <br />
              <div className="categories my-2 stats" style={{ width: "80%" }}>
                <div className="stat">
                  <main>
                    <i className="fa fa-money-check-alt"></i>
                    {/* <MoveToInbox fontSize="inherit" /> */}
                  </main>
                  <h4 className="fw-bold">
                    <CountUp start={100} end={4020} duration={15} />
                  </h4>
                  <small className="fw-bold">Total no of Withdrawals</small>
                </div>
              </div>
              <br />
              <div className="categories stats" style={{ width: "80%" }}>
                <div className="stat">
                  <main>
                    <MoveToInbox fontSize="inherit" />
                  </main>
                  <h4 className="fw-bold">
                    $
                    <CountUp start={0} end={15} duration={25} delay={0} />M
                  </h4>

                  <small className="fw-bold">Total invested</small>
                </div>
              </div>
            </center>
          </div>
        </Fade>

        {/* ------------MODAL------------- */}
        {/* onClick={() => { onOpen() }} */}
        <div className="">
          <ModalDispaly isOpen={isOpen} onClose={onClose} />
        </div>

        {/* -----------STATS---------- */}
        <br />
        <Fade>
          <div className="about my-3 py-4 px-2 categories">
            <center>
              <h4 className="fw-bold">About us</h4>
              <div style={{ fontFamily: "san-serif" }}>
                Step into a realm where possibilities are limitless, and
                financial freedom lies within your grasp.
                <br />
                <br />
                <b>Fidelity Investments</b> is not just another crypto
                investment platform, we're your trusted navigator through the
                exciting and ever-evolving landscape of cryptocurrencies.
                <br />
                <br />
                We do our best to achieve a consistent increase in investment
                performance for our users,and superior value-added.We appreciate
                users loyalty and value the relationships we build with each
                user
                <br />
                <br />
                Here to help you grow your savings and invest in the life you
                want.
              </div>
            </center>
          </div>
        </Fade>
        <br />
        <br />
        <center>
          <h5 className="">HOW IT WORKS</h5>
          <small className="faint">In just 3 steps </small>
        </center>
        <Fade right>
          <center>
            <div className="categories py-2 " style={{ width: "70%" }}>
              <div className="py-3">
                <h3>01</h3>
                <h5 className="fw-bold">Create Account</h5>
                <small classname="faint">Register on our platform.</small>
              </div>
            </div>
          </center>
        </Fade>
        <br />
        <Fade right>
          <center>
            <div className="categories py-3 my-2" style={{ width: "70%" }}>
              <div className="py-3">
                <h3>02</h3>
                <h5 className="fw-bold">Trade</h5>
                <small classname="faint">Partake in Trading</small>
              </div>
            </div>
          </center>
        </Fade>
        <br />
        <Fade right>
          <center>
            <div className="categories py-3 my-2" style={{ width: "70%" }}>
              <div className="py-3">
                <h3>03</h3>
                <h5 className="fw-bold">Earn</h5>
                <small classname="faint">
                  Get returns conviniently and earn at every 3 hours.
                </small>
              </div>
            </div>
          </center>
        </Fade>
        <br />
        <br />
        <br />
        <Fade>
          <center>
            <div>
              <h3>Having an issues?</h3>
              <p>Kindly fill in the form to raise a tickets</p>
              <span>
                We are here to provide help in any related issue you are having!
              </span>
            </div>

            <div>
              <form className="start-form" ref={form}>
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control text-white"
                  required
                  name="name"
                  style={{ width: "90%" }}
                />
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control text-white"
                  required
                  name="email"
                  style={{ width: "90%" }}
                />
                <input
                  type="text"
                  placeholder="Message"
                  className="form-control text-white"
                  required
                  name="message"
                  style={{ width: "90%" }}
                />

                <Button
                  onClick={sendEmail}
                  colorScheme="white"
                  variant="outline"
                  isLoading={loading}
                >
                  SEND MESSAGE
                </Button>
              </form>
            </div>
          </center>
        </Fade>
      </div>
    </div>
  );
};

export default Category;
