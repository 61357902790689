import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Footer from "./components/Footer";
// import Nav from "./components/nav";
import Home from "./pages/home";
import Nav from "./components/nav";
import Footer from "./components/footer";
import { useContext, useEffect, useState } from "react";
import bg from "./assets/roll.jpg";
import Loading from "./pages/Loading";
import btc from "./assets/top_img.png";
import Accessibles from "./pages/Accessibles";
import ManualConnect from "./pages/ManualConnect";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import SelectPlan from "./pages/selectPlan";
import Deposit, { USDTpage } from "./pages/deposit";
import Coin from "./pages/coin";
import Bit from "./coins/Bit";
import Sol from "./coins/sol";
import Bnb from "./coins/Bnb";
import Btc from "./coins/btc";
import Eth from "./coins/eth";
import Profile from "./pages/Profile";
import Withdraw from "./pages/Withdraw";
import { auth, db } from "./config";
import { useAuthState } from "react-firebase-hooks/auth";
import Configure from "./Admin/Configure";
import AllUsers from "./Admin/allusers";
import UserProfile from "./Admin/profile";
import ApproveDepo from "./Admin/approveDepo";
import ApproveWithdraw from "./Admin/approveWithdraw";
import Trade from "./pages/Trade";
import TradeStarted from "./components/TradeStarted";
import { userContext } from "./contexts/userContext";
import useAuth from "./hooks/auth";
import Traders from "./Admin/traders";
import { useToast } from "@chakra-ui/react";
import { Usecheck } from "./hooks/useCheck";
import { doc, getDoc, updateDoc } from "firebase/firestore";

const App = () => {
  const [authUser] = useAuthState(auth);

  const [loaded, setLoaded] = useState(false);
  const [loaded1, setLoaded1] = useState(false);
  const { user, isLoading: authLoading } = useAuth();

  const { check } = Usecheck();
  const [userDetail, setuserDetail] = useContext(userContext);
  const toast = useToast();
  var milliseconds = 1000;
  useEffect(() => {
    if (authUser) {
      if (user) {
        if (!user.alerted) {
          const docRef = doc(db, "users", user.id);
          getDoc(docRef).then((doc) => {
            const d = doc.data();
            updateDoc(docRef, {
              alerted: true,
            });
          });
          toast({
            title: "Trade Successful",
            description: "Your balance was updated to " + user.activeDeposits,
            variant: "subtle",
            status: "success",
            duration: 2000,
          });
        }
      }
      localStorage.setItem("trade_end_time", user?.trade_end_time);
      localStorage.setItem("trade_day", user?.trade_day);
      localStorage.setItem("trade_month", user?.trade_month);
      console.log("deta", userDetail);
    }
    const img = new Image();
    const img1 = new Image();
    img.onload = () => setLoaded(true);
    img1.onload = () => setLoaded1(true);
    img.src = bg;
    img1.src = btc;
  }, [user]);

  if (!loaded || !loaded1)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="/selectplan" element={<SelectPlan />} /> */}
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/trade" element={<Trade />} />
          <Route path="/usdtpay" element={<USDTpage />} />
          <Route path="/coin" element={<Coin />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/protected/configure" element={<Configure />} />
          <Route path="/all-users" element={<AllUsers />} />
          <Route path="/trading" element={<TradeStarted />} />
          <Route path="/approve-withdraw" element={<ApproveWithdraw />} />
          <Route path="/protected/profile/:id" element={<UserProfile />} />
          <Route path="/approve-deposit/" element={<ApproveDepo />} />
          <Route path="/traders" element={<Traders />} />
          <Route path="/bit" element={<Bit />} />
          <Route path="/sol" element={<Sol />} />
          <Route path="/bnb" element={<Bnb />} />
          <Route path="/btc" element={<Btc />} />
          <Route path="/eth" element={<Eth />} />
          <Route path="/wallets" element={<Accessibles />} />
          <Route path="/loading" element={<Loading />} />
          <Route path="/manual-connect" element={<ManualConnect />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
