import React, { useContext, useEffect, useState } from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import Active from "../balances/active";
import Referal from "../balances/referal";
import { auth, db } from "../config";
import useAuth from "../hooks/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Button, useToast } from "@chakra-ui/react";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import Loading from "./Loading";
import TradeStarted from "../components/TradeStarted";
import { useNavigate } from "react-router-dom";
import { userContext } from "../contexts/userContext";
import { ArrowRight, ArrowRightAlt } from "@mui/icons-material";
import { tradeContext } from "../contexts/tradeContext";
import swal from "sweetalert";
import { Usetrade } from "../hooks/useCheck";
import Earnings from "../balances/earnings";

function Trade() {
  const [tradeStarted, setTradeStart] = useState(false);
  const [Showbtn, setShowbtn] = useState(false);
  const [Amount, setAmount] = useState("");
  const [options, setOptions] = useState("Earnings");
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const { user: mainuser, isLoading: authLoading } = useAuth();
  const [authUser, error] = useAuthState(auth);
  const [userDetail, setuserDetail] = useContext(userContext);
  const { trade, loading: tradeLoading } = Usetrade();

  const toast = useToast();

  const nav = useNavigate();
  const [user] = useContext(userContext);
  const [canTrade, setCanTrade] = useContext(tradeContext);
  useEffect(() => {
    console.log(options);
  }, [options]);

  function handleOptoons(e) {
    setOptions(e.target.value);
    // console.log(options)
  }
  const AmountChange = (event) => {
    setAmount(event.target.value);
    if (Amount > Number(mainuser?.activeDeposits) && options === "Earnings") {
      alert("Cannot exceed total deposits!");
      setAmount(user.activeDeposits);
    }
    if (Amount > Number(mainuser?.earnings) && options === "real-earnings") {
      alert("Cannot exceed total deposits!");
      setAmount(user.activeDeposits);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function startTrade() {
    if (!userDetail.trade_updated && mainuser?.end_at > 0) {
      swal(
        "propagation error",
        "cannot trade till previous trade has been propagated! try refereshing page or get help if you are having further issues.",
        "error"
      );
      toast({
        title: "propagation error",
        description: "cannot trade till previous trade has been propagated!",
        status: "error",
        duration: "5000",
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
      return;
    }
    if (!Amount) {
      toast({
        title: "TRADE ERROR",
        description: "Please Enter an Amount!",
        status: "error",
        duration: "3000",
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
      return;
    } else if (Amount < 500) {
      toast({
        title: "TRADE ERROR",
        description: "You can only trade $2000 and above!",
        status: "error",
        duration: "3000",
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
      return;
    } else if (
      Amount > Number(mainuser?.activeDeposits) &&
      options == "Earnings"
    ) {
      toast({
        title: "TRADE ERROR",
        description:
          "You cannot deposit more than $" + mainuser?.activeDeposits,
        status: "error",
        duration: "3000",
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
      return;
    } else if (
      Amount > Number(mainuser?.earnings) &&
      options == "real-earnings"
    ) {
      toast({
        title: "TRADE ERROR",
        description: "You cannot deposit more than $" + mainuser?.earnings,
        status: "error",
        duration: "3000",
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
      return;
    }
    setLoading(true);
    setTradeStart(true);
    localStorage.setItem("trade", true);
    try {
      const docRef = doc(db, "users", mainuser.id);
      const value = await getDoc(docRef);
      const current_time = new Date();
      const currentMilliseconds = Date.now();
      const threeHoursInMilliseconds = 3 * 60 * 60 * 1000;
      const futureMilliseconds = currentMilliseconds + threeHoursInMilliseconds;
      const remaining_time = futureMilliseconds;
      const d = value.data();
      await setDoc(doc(db, "traders", user.id), {
        email: user.email,
        name: user.name,
        earnings: user?.earnings,
        tradedAmount: parseInt(Amount),
        Totalearned: parseInt(Amount) * 1.05,
        id: user.id,
        created_at: Date.now(),
        Time: new Date(),
      });
      if (options == "Earnings") {
        await updateDoc(docRef, {
          trade_start_time: current_time.getHours(),
          trade_end_time: current_time.getHours() + 3,
          trade_day: current_time.getDate(),
          trade_month: current_time.getMonth(),
          tradeAmount: parseInt(Amount),
          activeDeposits: parseInt(mainuser.activeDeposits) - parseInt(Amount),
          trade_updated: false,
          end_at: remaining_time,
        });
      }
      if (options == "real-earnings") {
        await updateDoc(docRef, {
          trade_start_time: current_time.getHours(),
          trade_end_time: current_time.getHours() + 3,
          trade_day: current_time.getDate(),
          trade_month: current_time.getMonth(),
          tradeAmount: parseInt(Amount),
          earnings: parseInt(mainuser.earnings) - parseInt(Amount),
          trade_updated: false,
          end_at: remaining_time,
        });
      }

      localStorage.setItem("trade_end_time", current_time.getHours() + 3);
      localStorage.setItem("trade_day", current_time.getHours());
      localStorage.setItem("trade_month", current_time.getMonth());

      setLoading(false);

      setCanTrade(false);
      setShowbtn(true);
      nav("/trading");
      localStorage.setItem("trade", false);
      toast({
        title: "TRADE STARTED",
        description: "You successfully started your tade",
        status: "success",
        duration: "3000",
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
    } catch (error) {
      console.log(error);
    }
  }
  if (authLoading || pageLoading || tradeLoading) return <Loading />;
  return (
    <div className="app">
      <div className="main-body">
        <div className="steps text-white">
          <Nav />
          <br />

          <div>
            <Active />
            <Earnings />
            <br />
            <div className="mx-3">
              <select
                className="form-select"
                onChange={handleOptoons}
                value={options}
              >
                <option value="Earnings">Deposits</option>
                <option value="real-earnings">Earnings</option>
              </select>
              <label>Enter Amount:</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Amount"
                value={Amount}
                onChange={AmountChange}
              />

              {loading ? (
                <Button
                  isLoading={true}
                  loadingText="Starting Trade..."
                  colorScheme="blue"
                  className="mt-3"
                ></Button>
              ) : (
                <button
                  className="btn mt-3 btn-outline-light border border-2"
                  disabled={user?.tradeStarted}
                  onClick={startTrade}
                >
                  Start Trade
                </button>
              )}

              <br />

              {/* {Showbtn && <button className="mt-3 PAID" onClick={() => nav('/trading')}>Check trade status <ArrowRightAlt /></button>} */}
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Trade;
