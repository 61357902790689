import React, { useEffect, useState } from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import {
  ArrowRight,
  ArrowRightAltOutlined,
  ContentPaste,
  Lock,
  QrCode,
  QrCode2,
} from "@mui/icons-material";
import eth from "../assets/1027.png";
import { Spinner, Toast, useToast } from "@chakra-ui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import swal from "sweetalert";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import useAuth from "../hooks/auth";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import { db } from "../config";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useDisclosure } from "@chakra-ui/react";
import QRmodal from "../components/qrmodal";

function Deposit(props) {
  const [amount, setAmount] = useState();
  const [value, setValue] = useState("");
  const [coin] = useState(JSON.parse(localStorage.getItem("plan")));
  const [feedback, setFeedback] = useState("");
  const { user, isLoading: authLoading } = useAuth();
  const nav = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const proceed = (e) => {
    e.preventDefault();
    const planDetails = { minDepo: 500 };
    if (!amount || amount < planDetails.minDepo) {
      toast({
        title: planDetails.name + " Error",
        description:
          "Amount must be atleast $" + planDetails.minDepo + " and above!",
        position: "top",
        status: "error",
        variant: "subtle",
        duration: "8000",
        isClosable: true,
      });

      return;
    } else {
      localStorage.setItem("amount", amount);
      toast({
        description: "Once payment is confirmed your balance will be updated!",
        status: "info",
        duration: "3000",
        isClosable: true,
        position: "top",
        colorScheme: "blue",
        variant: "subtle",
      });
      nav("/usdtpay");
    }
  };
  if (!user) return <Loading />;

  return (
    <div>
      <div className="app">
        <div className="main-body">
          <div className="plan_bg">
            <Nav />
            <br />
            <h4 className="faint text-center">Deposit Funds</h4>
            <p
              style={{ fontWeight: "bolder", color: "white", padding: "10px" }}
            >
              Note: Deposit must be $500 and above...
            </p>
            <form autoComplete="false" className="mx-4 mt-5">
              <input
                type="number"
                className=" mt-3 form-control  "
                placeholder="Enter amount($)"
                autoComplete="off"
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
              <select className="form-select mt-2">
                <option selected>ETH(ERC 20)</option>
              </select>

              <input
                type="hidden"
                placeholder="Message"
                className="form-control text-white"
                name="message"
                value={
                  user?.name + " claims he sent a worth of " + amount + " ETH"
                }
              />

              <button className="btn text-white MYbutton" onClick={proceed}>
                Proceed to make payment <ArrowRightAltOutlined />
              </button>
            </form>
            <br />
          </div>
          <br />
          <br />
          <br />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Deposit;

// PAGE TO DISPLAY WALLET ADDRESS
export function USDTpage(props) {
  const [amount, setAmount] = useState(
    JSON.parse(localStorage.getItem("amount"))
  );
  const [value, setValue] = useState(
    "0xe179FE5CF992650267A44FD30Bc65d5e2b4E3eD8"
  );
  const [coin] = useState(JSON.parse(localStorage.getItem("plan")));
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user, isLoading: authLoading } = useAuth();

  const Toast = useToast();
  const copied = () => {
    Toast({
      title: "Address copied",
      isClosable: true,
      duration: 2000,
      variant: "subtle",
      position: "top",
    });
  };
  // const navigate = useNavigate();
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    setLoading(true);
    emailjs

      .sendForm(
        "service_2dgc3fa",

        "template_dw6n13m",

        form.current,
        "g9C40xwxDFxlCPG9P"
      )

      // FD.append("service_id", "service_f6d8nxb");
      // // FD.append("template_id", "template_6ciy15u");
      // // FD.append("user_id", "yHBMX4yCStbeXbxgy");

      .then(
        (result) => {
          console.log(result.text);

          Toast({
            description:
              "Once payment is confirmed your balance will be updated!",
            status: "success",
            duration: "3000",
            isClosable: true,
            position: "top",
            colorScheme: "blue",
            variant: "subtle",
          });
          setDoc(doc(db, "Depositors", user?.id), {
            email: user?.email,
            referer: user?.userReferer,
            earnings: user?.earnings,
            coin: JSON.parse(localStorage.getItem("coin")).name || "PLAN",
            deposit: amount,
            created_at: serverTimestamp(),
          });

          localStorage.removeItem("plan");

          swal(
            "Almost there!",
            "Once payment is confirmed your balance will be updated!",
            "info"
          );
          setLoading(false);
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
          swal("", "An error occured", "info");
        }
      );
    // navigate("/dashboard");
  };

  return (
    <div className="app">
      <div className="main-body" style={{ height: "100vh" }}>
        <Nav />
        <QRmodal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

        <div
          className="plan_bg"
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <center>
            <div className="address-section ">
              <h5>ETH(ETHEREUM) </h5>
              <p style={{ color: "white", fontSize: "13px", padding: "10px" }}>
                Send only ETH (ERC 20) to this address. Sending any other coins
                may results in permanent loss.
              </p>
              <img src={eth} alt="" />
              <br />
              <div
                className="d-flex justify-content-center align-center "
                style={{ flexDirection: "column", alignItems: "center" }}
              >
                <div className="address-div"> {value}</div>
                <CopyToClipboard text={value}>
                  <button className="btn" onClick={copied}>
                    copy <ContentPaste />
                  </button>
                </CopyToClipboard>
                <br />
                <button className="btn qr" onClick={onOpen}>
                  SCAN&nbsp;
                  <QrCode2 />
                </button>
                <small className="faint mt-4">
                  <Lock fontSize="inherit" /> This session is end-to-end
                  encrypted.
                </small>
              </div>
            </div>
            <form ref={form}>
              <input
                type="hidden"
                placeholder="Message"
                className="form-control text-white"
                name="message"
                value={
                  user?.name + " claims they sent a worth of " + amount + "ETH"
                }
              />
              {loading ? (
                <div className="mt-3">
                  <Spinner color="white" colorScheme="white" />
                </div>
              ) : (
                <button onClick={sendEmail} className="PAID mt-4">
                  I've made payment
                </button>
              )}
            </form>
          </center>
        </div>
      </div>
    </div>
  );
}
