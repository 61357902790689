import React, { useContext, useEffect, useState } from "react";
import useCountdown from "../hooks/useCountDown";
import useAuth from "../hooks/auth";
import { format } from "date-fns";
import Nav from "./nav";
import { useNavigate } from "react-router-dom";
import { userContext } from "../contexts/userContext";
import Loading from "../pages/Loading";
import { tradeContext } from "../contexts/tradeContext";
import Footer from "./footer";
import { auth } from "../config";
import { useAuthState } from "react-firebase-hooks/auth";
import { ArrowRightAlt } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Usetrade } from "../hooks/useCheck";

const TradeStarted = () => {
  const { hours, minutes, seconds } = useCountdown();
  // const [user] = useContext(userContext)
  const [showTime, setShowTime] = useState(
    JSON.parse(localStorage.getItem("trade"))
  );
  const [loading, setLoading] = useState(false);
  const [canTrade, setCanTrade] = useContext(tradeContext);
  const [authUser] = useAuthState(auth);
  const [userDetail, setuserDetail] = useContext(userContext);
  const { trade, loading: tradeLoading } = Usetrade();
  const { user, isLoading: authLoading } = useAuth();
  const endTimeInMillis = new Date().getTime();
  const formattedTime = useCountdown(endTimeInMillis);
  const nav = useNavigate();
  const [newTimeRemaining] = useState(user?.end_at - Date.now());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (authLoading || loading || tradeLoading) return <Loading />;

  return (
    <div className="app">
      <div className="mainUser-body">
        <div className="steps text-white">
          <Nav />
          <center>
            <br />
            <br />
            <br />
            <div className="position-relative mb-4">
              <div class="loader">
                <div class="square" id="sq1"></div>
                <div class="square" id="sq2"></div>
                <div class="square" id="sq3"></div>
                <div class="square" id="sq4"></div>
                <div class="square" id="sq5"></div>
                <div class="square" id="sq6"></div>
                <div class="square" id="sq7"></div>
                <div class="square" id="sq8"></div>
                <div class="square" id="sq9"></div>
              </div>
            </div>
            <div className="mx-3">
              <div className="d-flex justify-content-center py-2 mb-1 rounded trade_div">
                <b>Trade ID: SW72I92Q</b>
              </div>
            </div>

            <div className="trade_div mx-3 px-3 py-4 rounded">
              <div>Amount Traded: </div> <div> ${user?.tradeAmount}</div>
              <div>Expected Earnings: </div>{" "}
              <div> ${(user?.tradeAmount * 1.05).toFixed(2)}</div>
              <div>Time Created: </div>{" "}
              <div>{format(Date.now(), "MMM YYY")}</div>
            </div>
          </center>

          {user?.end_at && (
            <div className="mx-3 mt-2 rounded">
              <center>
                <small className="faint">next trade in:</small>
              </center>
              <div className="d-flex justify-content-center py-2 rounded trade_div">
                <h3>{formattedTime}</h3>
              </div>
            </div>
          )}
          {!user.end_at && (
            <div className="mx-3 mt-2 rounded">
              <center>
                <small className="faint">next trade in:</small>
              </center>
              <div className="d-flex justify-content-center py-2 rounded trade_div">
                <h4>00hrs : 00mins : 00secs</h4>
              </div>
            </div>
          )}
          <center>
            {user?.end_at - Date.now() < 1 && (
              <div className="mt-2 faint" style={{ fontSize: "9px" }}>
                your trade status might have been updated, check your dashboard
                for changes.
              </div>
            )}
            <div className="mt-2">
              <Link to="/dashboard">
                <small>
                  back to dashbored <ArrowRightAlt />
                </small>
              </Link>
            </div>
          </center>

          <br />
          <br />
          <br />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default TradeStarted;
