import React, { useState, useEffect } from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import useAuth from "../hooks/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config";
import { useNavigate } from "react-router-dom";
import { Spinner, useToast } from "@chakra-ui/react";
import { ContentPaste, Save, SaveAlt } from "@mui/icons-material";
import CopyToClipboard from "react-copy-to-clipboard";

function Profile(props) {
  const [name, setName] = useState("user.name");
  const [email, setmail] = useState("user.email");
  const [referal, setRefereal] = useState("uuser.referale");

  const { user, isLoading: authLoading } = useAuth();
  const Toast = useToast();
  const [authUser, error] = useAuthState(auth);
  const nav = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!authUser) {
      nav("/login");
    } else {
      console.log("usr", user);
      console.log("auth", authUser.UserImpl);
    }
    setAdress(JSON.parse(localStorage.getItem("wallet_address")));
  }, []);
  const [address, setAdress] = useState();

  function copied() {
    Toast({
      title: "referal code copied",
      status: "info",
      colorScheme: "blue",
      variant: "subtle",
    });
  }
  const saveAddress = () => {
    setAdress(localStorage.setItem("wallet_address", JSON.stringify(address)));
    Toast({
      title: "Success",
      description: "Addres updated!",
      status: "info",
      colorScheme: "blue",
      variant: "subtle",
      position: "top",
    });
  };
  return (
    <div>
      <div className="app">
        <div className="main-body">
          <div className="text-white ">
            <Nav />
            <div className="mx-4 user-details">
              <br />
              <h5>Account Details</h5>
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                disabled
                value={user.name ? user.name : "user"}
              />
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                disabled
                value={user.email ? user.email : "user gmail..."}
              />
              <label>Referal Code</label>
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control input_button"
                  disabled
                  value={user.referalCode ? user.referalCode : "BS.."}
                  style={{ height: "42px" }}
                />
                <CopyToClipboard text={user?.referalCode}>
                  <button
                    className="btn btn-light button_input"
                    style={{ height: "42px", whiteSpace: "nowrap" }}
                    onClick={copied}
                  >
                    copy <ContentPaste fontSize="small" />
                  </button>
                </CopyToClipboard>
              </div>
              <label>Wallet Address</label>
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control input_button"
                  // placeholder={address == "" ? "NOT SET": address}
                  value={address}
                  onChange={(e) => {
                    setAdress(e.target.value);
                  }}
                />
                <button
                  className="btn btn-light button_input"
                  disabled={
                    address?.length < 26 ||
                    address?.length === "" ||
                    address?.length === undefined ||
                    address?.length === 0
                      ? true
                      : false
                  }
                  style={{
                    height: "42px",
                    whiteSpace: "nowrap",
                    color: "#05102df4",
                  }}
                  onClick={saveAddress}
                >
                  save <SaveAlt fontSize="small" />
                </button>
              </div>
              <label>Language</label>
              <select className="form-select" disabled>
                <option selected>English</option>
                <option value="">Korean</option>
                <option value="">Chisese</option>
              </select>
            </div>
          </div>
          <br />
          <br />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Profile;
