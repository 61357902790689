import React from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import Plans from "../components/Plans";
import Loading from "./Loading";

function SelectPlan() {
  return (
    <div className="app">
      <div className="main-body">
        <div className="plan_bg">
          <div className="choose-coin text-white">
            <Nav />
            <br />
            <center>
              <h3 className="text-center text-white">INVESTMENT PLANS</h3>
              <center>
                {" "}
                <small className="faint text-center">
                  select an investment plan.
                </small>
              </center>
              <Plans />
            </center>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SelectPlan;
